import React, { Fragment } from "react";
import Introduction from "../components/Introduction";
import LoginDone from "../components/LoginDone";

import { useAuth0 } from "@auth0/auth0-react";

const Home = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
    });

  return (
    <Fragment>
      {!isAuthenticated && (
        <>
        <Introduction />
        <div className="hero">
          <button
            // href="/api/auth/login" 
            className="btn btn-primary btn-block customButton"
            onClick={() => loginWithRedirect({})}
          >
            <span className='customButtonText'>
              ログインして 性格診断を始める
            </span>
          </button>
        </div>
        </>
      )}
      {isAuthenticated && (
        <>
        <LoginDone />
        <hr />
        <div className="hero">
          <button
            className="btn btn-primary btn-block customButton"
            onClick={() => logoutWithRedirect()}
          >
            <span className='customButtonText'>
              ログアウトする
            </span>
          </button>
        </div>
        </>
      )}
    </Fragment>
  );
}

export default Home;
